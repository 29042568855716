import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { IoPersonCircleSharp } from 'react-icons/io5'; // A playful user icon
import './Navbar.css' // Style file for the navbar
import { FaBars, FaTimes, FaChevronDown } from 'react-icons/fa'; // Import dropdown icon
import Logo from '../assets/images/WonderYarnLogo.png'; 
import i18n from '../i18n'; // Assuming i18next is set up
import USFlag from '../assets/flags/us.svg'; // USA flag for English
import ESFlag from '../assets/flags/es.svg'; // Spain flag for Spanish
import { useTranslation } from 'react-i18next';
import LoginModal from './LoginModal';
import RegisterModal from './RegisterModal';
import { useAuth } from '../contexts/AuthContext';
//
function Navbar() {
  const { t, i18n } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false); // State for the dropdown
  const [mobileLanguageOpen, setMobileLanguageOpen] = useState(false)
  const [languageOpen, setLanguageOpen] = useState(false)
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language)
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [isRegisterModalOpen, setRegisterModalOpen] = useState(false);
  const [userDropdownOpen, setUserDropdownOpen] = useState(false); 
const [isDropdownHovered, setIsDropdownHovered] = useState(false);
const [isItemHovered, setIsItemHovered] = useState(false);
  const { user, loading, signOut } = useAuth();
  const menuRef = useRef(null)
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const toggleLanguage = () => setLanguageOpen(!languageOpen)
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };


  const openModal = () => setLoginModalOpen(true);
  const closeModal = () => setLoginModalOpen(false);
  const closeRegisterModal = () =>  setRegisterModalOpen(false);
  const toggleMobileLanguage = () => setMobileLanguageOpen(!mobileLanguageOpen)

  const toggleUserDropdown = () => {
    setUserDropdownOpen(!userDropdownOpen);
  };

 const openLoginModal = () => {
    setLoginModalOpen(true);
    setRegisterModalOpen(false); // Ensure only one modal is open at a time
  };

  const openRegisterModal = () => {
    setRegisterModalOpen(true);
    setLoginModalOpen(false); // Ensure only one modal is open at a time
  };

  const closeModals = () => {
    setLoginModalOpen(false);
    setRegisterModalOpen(false);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change language using i18next
    setDropdownOpen(false); // Close dropdown after selecting language
    setMobileLanguageOpen(false)
    setLanguageOpen(false)
  };
  const getLanguageDisplay = (lang) => {
    switch (lang) {
      case 'en':
        return { flag: USFlag, name: 'English' }
      case 'es':
        return { flag: ESFlag, name: 'Español' }
      default:
        return { flag: USFlag, name: 'English' }
    }
  }
  const currentLangDisplay = getLanguageDisplay(i18n.language)
  const [showForm, setShowForm] = useState(false)
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        userDropdownOpen
      ) {
        setUserDropdownOpen(false); // Close dropdown if clicking outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [menuRef, userDropdownOpen])
  return (
    <>
      {/* Hamburger icon for mobile */}
      <div className='hamburger' onClick={toggleMenu}>
        <FaBars />
      </div>

      {/* Desktop Navbar */}
      <header className='navbar'>
        <div className="logo-container">
          <Link to='/'>
            <img src={Logo} alt="WonderYarn Logo" className="navbar-logo" />
          </Link>
        </div>
        <nav className='nav-links'>
          {user ? (
            <div className="user-dropdown">
		  <button
  className="user-dropdown-btn"
  onClick={toggleUserDropdown}
  onMouseEnter={() => setIsDropdownHovered(true)}
  onMouseLeave={() => setIsDropdownHovered(false)}
>
		    <IoPersonCircleSharp
    className="user-icon"
    style={{
      color: isItemHovered ? '#ffffff' :
             isDropdownHovered ? '#fbd25d' :
             '#ffffff'
    }}
  />
                {user.name || " "}
                <FaChevronDown />
              </button>
              {userDropdownOpen && (
                <div className="user-dropdown-content" ref={menuRef}>
                  <Link to="/profile" className="dropdown-item" onMouseEnter={() => setIsItemHovered(true)} onMouseLeave={() => setIsItemHovered(false)}>
                    👤 {t('profile')}
                  </Link>
                  <Link onClick={signOut} className="dropdown-item" onMouseEnter={() => setIsItemHovered(true)} onMouseLeave={() => setIsItemHovered(false)}>
                    🚪 {t('logout')}
                  </Link>
                </div>
              )}
            </div>
          ) : (
		  <>
	  <button onClick={openModal}>{t('login')}</button>
          <LoginModal isOpen={isLoginModalOpen} onClose={closeModals} openRegister={openRegisterModal} />
	  <button onClick={openRegisterModal}>{t('register')}</button>
          <RegisterModal isOpen={isRegisterModalOpen} onClose={closeModals} openLogin={openLoginModal} />
          <Link to='/about' className='nav-link'>
            {t('about')}
          </Link>
		  </>
	  )}
          {/* Language dropdown */}
          <div className='dropdown'>
            <button className='dropdown-btn' onClick={toggleDropdown}>
              <img src={currentLangDisplay.flag} alt={currentLangDisplay.name} className="current-flag-icon" />
              {currentLangDisplay.name}
              <FaChevronDown />
            </button>
            {dropdownOpen && (
              <div className='dropdown-content'>
                <button onClick={() => changeLanguage('en')}>
                  <img src={USFlag} alt="English" className="flag-icon" />
                  English
                </button>
                <button onClick={() => changeLanguage('es')}>
                  <img src={ESFlag} alt="Español" className="flag-icon" />
                  Español
                </button>
              </div>
            )}
          </div>
        </nav>
      </header>

      {/* Mobile Menu */}
      <div ref={menuRef} className={`hamburger-menu ${menuOpen ? 'menu-open' : ''}`}>
        <div className='close-menu' onClick={toggleMenu}>
          <FaTimes />
        </div>
        <Link to='/' className='nav-link' onClick={toggleMenu}>
          {t('home')}
        </Link>
        <Link to='/about' className='nav-link' onClick={toggleMenu}>
          {t('about')}
        </Link>
        <Link to='/contact' className='nav-link' onClick={toggleMenu}>
          {t('contact-us')}
        </Link>
        <div className='mobile-language-menu'>
          <button className='mobile-language-btn' onClick={toggleMobileLanguage}>
	  <img src={currentLangDisplay.flag} alt={currentLangDisplay.name} className="current-flag-icon" />
	              {currentLangDisplay.name}
            {languageOpen ? <FaChevronUp /> : <FaChevronDown />}
	  {/* {t('language')} <FaChevronDown /> */}
          </button>
          {mobileLanguageOpen && (
            <div className='mobile-language-options'>
              <button onClick={() => changeLanguage('en')}>
                <img src={USFlag} alt="English" className="flag-icon" />
                English
              </button>
              <button onClick={() => changeLanguage('es')}>
                <img src={ESFlag} alt="Español" className="flag-icon" />
                Español
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Navbar
