import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import LandingPage from './components/LandingPage';
import InputForm from './components/InputForm';
import StoryDisplay from './components/StoryDisplay';
import PaymentModal from './components/PaymentModal';
import ContactUs from './components/ContactUs';
import AboutUs from './components/AboutUs';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import Error from './components/Error';
import Register from './components/Register';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { initGA4, logPageView } from './analytics';
import ErrorBoundary from './ErrorBoundary';
import { WebSocketProvider } from './contexts/WebSocketContext';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import PrivateRoute from './components/PrivateRoute'
import VerifyEmail from './components/VerifyEmail'
import Home from './components/Home';
import Profile from './components/Profile';
function App() {
  useEffect(() => {
    initGA4();
  }, []);

  const [isFooterVisible, setFooterVisible] = useState(false);
  const toggleFooter = () => setFooterVisible(!isFooterVisible);

  const RouteChangeTracker = () => {
    const location = useLocation();
    useEffect(() => {
      logPageView();
    }, [location]);

    return null;
  };

  return (
    <WebSocketProvider>
        <Router>
      <AuthProvider>
          <RouteChangeTracker />
          <div className="App">
            <Navbar />
            <Routes>
              <Route path="/index.html" element={<Navigate to="/" replace />} />
              <Route path="/" element={<LandingPage />} />

              {/* Protected Routes */}
              <Route path="/create" element={<InputForm />} />
              <Route path="/story" element={<StoryDisplay />} />
              <Route path="/story/:slug" element={<ErrorBoundary><StoryDisplay /></ErrorBoundary>} />
              {/* Public Routes */}
              <Route path="/about" element={<AboutUs />} />
              <Route path="/contact" element={<ContactUs />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/terms" element={<TermsOfService />} />
              <Route path="/oops" element={<Error />} />
	      <Route path="/register" element={<Register />} />
	      <Route path="/verify-email" element={<VerifyEmail />} />
	      <Route path="/profile" element={<Profile />} />
              <Route path="*" element={<Error />} />
            </Routes>

            {/* Sticky Footer */}
            <div className="footer-button" onClick={toggleFooter}>
              <FontAwesomeIcon icon={isFooterVisible ? faChevronUp : faChevronDown} />
            </div>
            <footer className={`footer ${isFooterVisible ? 'footer-visible' : ''}`}>
              <div className="footer-content">
                <nav className="footer-nav">
                  <a className="footer-link" href="/about">About Us</a>
                  <a className="footer-link" href="/contact">Contact Us</a>
                  <a className="footer-link" href="/privacy">Privacy Policy</a>
                  <a className="footer-link" href="/terms">Terms of Service</a>
                </nav>
                <p>© 2024 WonderYarn. All Rights Reserved.</p>
              </div>
            </footer>
          </div>
      </AuthProvider>
        </Router>
    </WebSocketProvider>
  );
}

export default App;

