// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// https://firebase.google.com/docs/auth/web/google-signin#expandable-4
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
  //authDomain: "asn-firewall-156219.firebaseapp.com",
const firebaseConfig = {
  apiKey: "AIzaSyDugYot_-8gKVG8psMLAJcoY1C1nzY3rK4",
  authDomain: "auth.wonderyarn.com",
  projectId: "asn-firewall-156219",
  storageBucket: "asn-firewall-156219.firebasestorage.app",
  messagingSenderId: "393670210476",
  appId: "1:393670210476:web:a2ca16c568f4892ba18009",
  measurementId: "G-P0YV9Q8C39"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
export default app;
