import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import { sendEmailVerification } from 'firebase/auth';

const VerifyEmail = () => {
  const { user } = useAuth();

  const resendVerificationEmail = async () => {
    if (user && !user.emailVerified) {
      await sendEmailVerification(user);
      alert('Verification email sent!');
    }
  };

  return (
    <div>
      <h1>Verify Your Email</h1>
      <p>Please verify your email address to continue.</p>
      <button onClick={resendVerificationEmail}>Resend Verification Email</button>
    </div>
  );
};

export default VerifyEmail;

