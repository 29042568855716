import React, { useState } from 'react';
import './LoginModal.css';  // We'll reuse the same CSS
import { auth } from '../firebase';
import { createUserWithEmailAndPassword, sendEmailVerification, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import wonderyarnLogo from '../assets/wonderyarn-mascot.png';
import { useTranslation } from 'react-i18next';

function RegistrationModal({ isOpen, onClose, openLogin }) {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [ageConfirmed, setAgeConfirmed] = useState(false);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const provider = new GoogleAuthProvider();

  const handleGoogleSignIn = async () => {
    try {
      await signInWithPopup(auth, provider);
      onClose();
    } catch (err) {
      setError(err.message);
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    if (!ageConfirmed) {
      setError('You must confirm that you are at least 13 years old.');
      return;
    }
    if (!agreeToTerms) {
      setError('You must agree to the Terms of Service and Privacy Policy.');
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      await sendEmailVerification(userCredential.user);
      setMessage('Verification email sent! Please check your inbox.');
      setTimeout(() => {
        onClose();
        navigate('/');
      }, 2000);
    } catch (err) {
      setError(err.message);
    }
  };

  if (!isOpen) return null;

  return (
    <div className='modal-overlay'>
      <div className='modal-content'>
        <button className='close-button-solid' onClick={onClose}>✖</button>
        <div className='content'>
          <h1>{t('create-account')}</h1>
          {error && <p className='error-message'>{error}</p>}
          {message && <p className='success-message'>{message}</p>}
          
          <form onSubmit={handleRegister}>
            <input
              type='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder='Email'
              required
            />
            <input
              type='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder='Password'
              required
            />
            <input
              type='password'
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder='Confirm Password'
              required
            />

            <div className='checkbox-wrapper'>
              <div className='checkbox-group'>
                <input
                  type='checkbox'
                  id="age-confirm"
                  checked={ageConfirmed}
                  onChange={(e) => setAgeConfirmed(e.target.checked)}
                />
                <label htmlFor="age-confirm">
                  {t('age-confirmation')}
                </label>
              </div>

              <div className='checkbox-group'>
                <input
                  type='checkbox'
                  id="terms-agree"
                  checked={agreeToTerms}
                  onChange={(e) => setAgreeToTerms(e.target.checked)}
                />
                <label htmlFor="terms-agree">
                  {t('i-agree-to-the')} <a href='/terms' target='_blank'>{t('terms')}</a> {t('and')}{" "} <a href='/privacy' target='_blank'>{t('privacy')}</a>
                </label>
              </div>
            </div>

            <button type='submit' className='login-button'>
              {t('register')}
            </button>
          </form>

          <p>{t('or')}</p>
          <button className='google-button' onClick={handleGoogleSignIn}>
	       <i className="fab fa-google"></i>{t('sign-up-with')} Google
          </button>

          <div className='additional-links'>
            <a href='#' onClick={(e) => {
		    e.preventDefault();
		    openLogin();
	    }}>{t('have-account')} {t('login')}</a>
          </div>
        </div>
        <img src={wonderyarnLogo} alt='WonderYarn Mascot' className='mascot-login' />
      </div>
    </div>
  );
}

export default RegistrationModal;
