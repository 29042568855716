import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

function PrivateRoute({ children }) {
  const { user, loading } = useAuth();
  const location = useLocation();

  // If the authentication state is still loading, show a loading message
  if (loading) {
    return <div>Loading...</div>;
  }

  // If the user is not authenticated, redirect them to the login page
  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  if (!user.emailVerified) return <Navigate to="/verify-email" replace />;
  // If the user is authenticated, render the child components
  return children;
}

export default PrivateRoute;

