import React, { useState, useEffect } from 'react';
import { getAuth, updateEmail, updatePassword } from 'firebase/auth';
import './AboutUs.css'; // Reuse the CSS for consistent styling
import { useTranslation } from 'react-i18next';
import PrivateRoute from './PrivateRoute';

function Profile() {
  const { t, i18n } = useTranslation();
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const auth = getAuth();

  useEffect(() => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      setUser(currentUser);
      setEmail(currentUser.email || '');
    }
  }, [auth]);

  const handleEmailUpdate = async () => {
    if (user && email) {
      try {
        await updateEmail(user, email);
        setStatusMessage('Email updated successfully!');
      } catch (error) {
        setStatusMessage(`Error updating email: ${error.message}`);
      }
    }
  };

  const handlePasswordUpdate = async () => {
    if (user && password) {
      try {
        await updatePassword(user, password);
        setStatusMessage('Password updated successfully!');
      } catch (error) {
        setStatusMessage(`Error updating password: ${error.message}`);
      }
    }
  };

  return (
    <PrivateRoute>
      <div className="about-us-page">
        <div className="stars"></div> {/* Stars background */}
        <div className="content-with-mascot content-with-aboutus">
          <div className="content">
            <h1>Profile</h1>
            {user ? (
              <>
                <p>{t('oh-hey')} {user.displayName || ''}! {t('sprinkle-magic')} 🪄✨</p>
                <div>
                  <label>
                    <strong>Email:</strong>
                  </label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter new email"
                  />
                  <button onClick={handleEmailUpdate}>Update Email</button>
                </div>
                <div>
                  <label>
                    <strong>Password:</strong>
                  </label>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter new password"
                  />
                  <button onClick={handlePasswordUpdate}>Update Password</button>
                </div>
                <p style={{ color: 'yellow' }}>{statusMessage}</p>
              </>
            ) : (
              <p>Loading user information...</p>
            )}
          </div>
        </div>
      </div>
    </PrivateRoute>
  );
}

export default Profile;

