import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth } from '../firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  // Sign-out function
  const handleSignOut = async () => {
    try {
      await signOut(auth); // Use the imported signOut method here
      setUser(null);
      navigate('/login'); // Redirect to the login page after sign-out
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };
  if (loading) {
    return <div>Loading...</div>;
  }

  return <AuthContext.Provider value={{ user, loading, signOut:handleSignOut }}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext(AuthContext);
}

