import React, { useState } from 'react';
import './LoginModal.css'; // Reuse the same styles
import { auth } from '../firebase';
import { sendPasswordResetEmail } from 'firebase/auth';
import wonderyarnLogo from '../assets/wonderyarn-mascot.png';
import { useTranslation } from 'react-i18next';

function ForgotPasswordModal({ isOpen, onClose }) {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('A password reset email has been sent. Please check your inbox.');
    } catch (err) {
      setError(err.message || 'Failed to send password reset email.');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button-solid" onClick={onClose}>✖</button>
        <div className="content">
          <h1>{t('forgot-password')}</h1>
          {error && <p className="error-message">{error}</p>}
          {message && <p className="success-message">{message}</p>}
          <form onSubmit={handleResetPassword}>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
            />
            <button type="submit" className="login-button">
              {t('send-reset-link')}
            </button>
          </form>
        </div>
        <img src={wonderyarnLogo} alt="WonderYarn Mascot" className="mascot" />
      </div>
    </div>
  );
}

export default ForgotPasswordModal;

