import React, { useState } from 'react';
import ForgotPasswordModal from './ForgotPasswordModal'; // Import the new modal
import './LoginModal.css';
import { auth } from '../firebase';
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import wonderyarnLogo from '../assets/wonderyarn-mascot.png';
import { useTranslation } from 'react-i18next';

function LoginModal({ isOpen, onClose, openRegister }) {
  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isForgotPasswordOpen, setForgotPasswordOpen] = useState(false);
  const provider = new GoogleAuthProvider();

  const handleGoogleSignIn = async () => {
    try {
      await signInWithPopup(auth, provider);
      onClose(); // Close modal on successful login
    } catch (err) {
      setError(err.message);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');

    try {
      await signInWithEmailAndPassword(auth, email, password);
      onClose(); // Close modal on successful login
    } catch (err) {
      setError('Invalid email or password');
    }
  };

  if (!isOpen) return null;

  return (
    <>
	        {isOpen && (
	  <div className='modal-overlay'>
      <div className='modal-content'>
        <button className='close-button-solid' onClick={onClose}>✖</button>
        <div className='content'>
          <h1>Login to Your Account</h1>
          {error && <p className='error-message'>{error}</p>}
          <form onSubmit={handleLogin}>
            <input
              type='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder='Email'
              required
            />
            <input
              type='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder='Password'
              required
            />
            <button type='submit' className='login-button'>Login</button>
          </form>
          <p>{t('or')}</p>
          <button className='google-button' onClick={handleGoogleSignIn}>
               <i className="fab fa-google"></i>{t('sign-in-with')} Google
          </button>
          <div className='additional-links'>

            <a href='#' onClick={(e) => {

e.preventDefault();
		    openRegister();
	    }}>
		    {t('no-account')} { t('register') }</a>
            <a href='#' onClick={(e) => {
		    e.preventDefault();
		    setForgotPasswordOpen(true);
	    }}>{t('forgot-password')}</a>
          </div>
        </div>
        <img src={wonderyarnLogo} alt='WonderYarn Mascot' className='mascot-login' />
      </div>
    </div>
		)}
			      <ForgotPasswordModal
        isOpen={isForgotPasswordOpen}
        onClose={() => setForgotPasswordOpen(false)}
      />
    </>
  );
}

export default LoginModal;

