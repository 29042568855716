import React, { useState } from 'react';
import './Register.css';
import { auth } from '../firebase';
import { createUserWithEmailAndPassword, sendEmailVerification, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import wonderyarnLogo from '../assets/wonderyarn-mascot.png';

function Register() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [ageConfirmed, setAgeConfirmed] = useState(false);
  const [parentConsent, setParentConsent] = useState(false);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const provider = new GoogleAuthProvider();

  const handleGoogleSignIn = async () => {
    try {
      await signInWithPopup(auth, provider);
      navigate('/');
    } catch (err) {
      setError(err.message);
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');

    // Validation
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    if (!ageConfirmed) {
      setError('You must confirm that you are at least 13 years old.');
      return;
    }
    if (!agreeToTerms) {
      setError('You must agree to the Terms of Service and Privacy Policy.');
      return;
    }
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Send verification email
      await sendEmailVerification(user);
      setMessage('Verification email sent! Please check your inbox.');

      // Redirect user to login page after registration
      navigate('/login');
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className='register-page'>
      <div className='stars'></div>
      <div className='content-with-mascot'>
        <div className='content'>
          <h1>Create an Account</h1>
          {error && <p className='error-message'>{error}</p>}
	  {message && <p style={{ color: 'green' }}>{message}</p>}
          <form onSubmit={handleRegister}>
            <input
              type='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder='Email'
              required
            />
            <input
              type='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder='Password'
              required
            />
            <input
              type='password'
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder='Confirm Password'
              required
            />
            
            {/* Age Confirmation */}
            <div className='checkbox-group'>
                <input
                  type='checkbox'
                  checked={ageConfirmed}
                  onChange={(e) => setAgeConfirmed(e.target.checked)}
                />
               <label> I confirm that I am at least 13 years old</label>
            </div>

            {/* Terms of Service and Privacy Policy */}
            <div className='checkbox-group'>
                <input
                  type='checkbox'
                  checked={agreeToTerms}
                  onChange={(e) => setAgreeToTerms(e.target.checked)}
	  	/>
                <label>I agree to the <a href='/terms' target='_blank'>Terms of Service</a> and <a href='/privacy' target='_blank'>Privacy Policy</a></label>
            </div>

            <button type='submit' className='register-button'>Register</button>
          </form>
          <p>Or sign up with:</p>
          <button className='google-button' onClick={handleGoogleSignIn}>
            Sign in with Google
          </button>
        </div>
        <div className='mascot-container'>
          <img src={wonderyarnLogo} alt='WonderYarn Mascot' className='mascot' />
        </div>
      </div>
    </div>
  );
}

export default Register;

